<template>
  <div class="v_my_center g-flex-column">
    <head-back :isBack="false">
      <template>
        <span> 我的 </span>
      </template>
    </head-back>
    <div class="v-my-center-container">
      <div class="v-my-center-head g-flex-align-center">
        <img class="v-my-head-image" :src="userInfo.head_img" alt="" @click="imagePreviewClick(userInfo.head_img)">
        <div class="v-my-head-info-box g-flex-column">
          <p class="v-my-head-id-box">ID:
            <span class="v-my-head-id">{{userInfo.id}}</span>
          </p>
          <p class="v-my-head-id-box">
            <span class="v-my-head-id">{{userInfo.mobile}}</span>
          </p>
        </div>
      </div>

      <div class="v-my-center-money">
        <ul class="v-my-center-money-list g-flex-align-center" @click="$router.push({ name: 'balance' })">
          <li class="v-my-center-money-list-item g-flex-column g-flex-align-center g-flex-justify-end">
            <span class="v-my-center-money-item-num">{{userInfo.principal}}</span>
            <span class="v-my-center-money-item-title">本金</span>
          </li>
          <li class="v-my-center-money-list-item g-flex-column g-flex-align-center g-flex-justify-end">
            <span class="v-my-center-money-item-num">{{userInfo.return_principal}}</span>
            <span class="v-my-center-money-item-title">未返本金</span>
          </li>
          <li class="v-my-center-money-list-item g-flex-column g-flex-align-center g-flex-justify-end">
            <span class="v-my-center-money-item-num">{{userInfo.commission}}</span>
            <span class="v-my-center-money-item-title">佣金</span>
          </li>
          <li class="v-my-center-money-list-item g-flex-column g-flex-align-center g-flex-justify-between">
            <span class="v-my-center-money-item-num">{{userInfo.cash_money}}</span>
            <span class="v-my-center-money-item-title">提现中</span>
          </li>
          <li class="v-my-center-money-list-item g-flex-column g-flex-align-center g-flex-justify-end" @click.stop="cashoutClick">
            <span class="iconfont icon-piaoju"></span>
            <span class="v-my-center-money-item-title">我要提现</span>
          </li>
        </ul>
      </div>

      <div class="v-my-center-cardone">
        <ul class="v-my-center-cardone-list">
          <li class="v-my-center-cardone-item g-flex-align-center" @click="$router.push({ name: 'safecenter' })">
            <div class="v-cardone-item-left g-flex-align-center">
              <i class="iconfont icon-lianjie"></i> 
              <span class="v-cardone-item-left-text">账号绑定</span>
            </div>
            <div class="v-cardone-item-right g-flex-align-center">
              <i class="iconfont icon-you"></i>
            </div>
          </li>
          <li class="v-my-center-cardone-item g-flex-align-center" @click="$router.push({ name: 'accountcomplete' })">
            <div class="v-cardone-item-left g-flex-align-center">
              <i class="iconfont icon-wancheng"></i> 
              <span  class="v-cardone-item-left-text">账号完成率</span>
            </div>
            <div class="v-cardone-item-right g-flex-align-center">
              <span class="v-cardone-item-right-text">{{userInfo.reach_rate}}%</span>
              <i class="iconfont icon-you"></i>
            </div>
          </li>
          <li class="v-my-center-cardone-item g-flex-align-center" @click="$router.push({ name: 'mypromote' })">
            <div class="v-cardone-item-left g-flex-align-center">
              <i class="iconfont icon-qunzu"></i> 
              <span  class="v-cardone-item-left-text">推广赚钱</span>
            </div>
            <div class="v-cardone-item-right g-flex-align-center">
              <i class="iconfont icon-you"></i>
            </div>
          </li>
        </ul>

      </div>

      <div class="v-my-center-cardtwo">
        <ul class="v-my-center-cardtwo-list">
          <li class="v-my-center-cardtwo-item g-flex-align-center" @click="copyClick($global.config.BUY_KEFU.qq)">
            <div class="v-cardtwo-item-left g-flex-align-center">
              <img src="../../assets/img/qq.png" alt=""> 
              <span class="v-cardtwo-item-left-text">QQ客服</span>
            </div>
            <div class="v-cardtwo-item-right g-flex-align-center">
              <span  class="v-cardtwo-item-left-text">{{$global.config.BUY_KEFU.qq}}</span>
              <i class="iconfont icon-you"></i>
            </div>
          </li>
          <li class="v-my-center-cardtwo-item g-flex-align-center" @click="copyClick($global.config.BUY_KEFU.wechat)">
            <div class="v-cardtwo-item-left g-flex-align-center">
              <img src="../../assets/img/wechat.png" alt=""> 
              <span  class="v-cardtwo-item-left-text">客服微信</span>
            </div>
            <div class="v-cardtwo-item-right g-flex-align-center">
              <span  class="v-cardtwo-item-left-text">{{$global.config.BUY_KEFU.wechat}}</span>
              <i class="iconfont icon-you"></i>
            </div>
          </li>
        </ul>
      </div>

      <div class="v-my-center-exit-login">
        <operation-button @clickItem="emitClickItem" :buttonObj="buttonObj"/>
      </div>

      <my-center-cash-pop ref="MyCenterCashPop"/>
    </div>

  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  import { apiGetUserInfo } from '@/utils/api.js'
  import MyCenterCashPop from './MyCenterCashPop.vue'
  import { ImagePreview } from 'vant';
  import OperationButton from '../../components/OperationButton.vue'
  export default {
    components: { HeadBack, MyCenterCashPop, OperationButton },
    created() {
      this.apiGetUserInfoHandel()
    },
    data() {
      return {
        // principal 本金
        // commission 佣金
        // real_status 0-未实名 1-审核中 2-实名成功 3-审核不通过
        // return_commission 未返佣金
        // return_principal 未返本金
        userInfo: this.$global.userInfo,
        buttonObj: {
          claSS: 'c-btn-default c-btn-border-radius',
          title: '退出登录',
          event: 'exitLogin'
        }
      }
    },
    methods: {
      exitLogin() {
        this.$global.logout()
      },
      emitClickItem(event) {
        if(!event) return
        this[event]()
      },

      //查看头像
      imagePreviewClick(url) {
        ImagePreview([url])
      },
      // 获取用户信息
      async apiGetUserInfoHandel() {
        this.$global.loadingShow = true
        const { success, data } = await apiGetUserInfo()
        if(!success) return
        this.userInfo = data.data
        this.$global.setUserInfo(data.data)
        console.log(data)
      },
      cashoutClick() {
        this.$refs.MyCenterCashPop.onShow()
      },
      copyClick(message) {
        this.$copyText(message).then(() => {
          this.$toast({
            type: 'success',
            message: '复制成功'
          });
        },  () => {
          this.$toast('复制失败,请重试');
        })
      },
    }
  }
</script>

<style lang="scss">
.v_my_center {
  background: #F1F1F1;
  .v-my-center-container {
    flex: 1;
    overflow: auto;
  }
  .v-my-center-head {
    min-height: 128px;
    background: $main_color;
    padding: 0 20px;
    .v-my-head-image {
      border-radius: 50%;
      width: 66px;
      height: 66px;
      object-fit: cover;
    }
    .v-my-head-info-box {
      .v-my-head-id-box {
        color: $white;
        font-size: 18px;
        flex: 1;
        padding-left: 20px;
        padding-right: 20px;
        line-height: 26px;
        .v-my-head-id {
          letter-spacing: 1px;
        }
      }
    }

  }
  .v-my-center-money {
    padding: 0 12px;
    position: relative;
    min-height: 70px;
    .v-my-center-money-list {
      background: $white;
      position: absolute;
      top: -22px;
      border-radius: 8px;
      left: 50%;
      transform: translateX(-50%);
      width: 92%;
      padding: 16px 0;
      .v-my-center-money-list-item {
        flex: 1;
        .icon-piaoju {
          font-size: 22px;
          line-height: 24px;
          color: $main_color;
        }
        .v-my-center-money-item-num {
          font-size: 14px;
          color: $main_color;
          line-height: 24px;
        }
        .v-my-center-money-item-title {
          padding-top: 12px;
          font-size: 12px;
          color: #000000;
        }
      }
    }
  }

  .v-my-center-cardone {
    padding: 7px 12px 14px 12px;
    .v-my-center-cardone-list {
      background: $white;
      border-radius: 8px;
      .v-my-center-cardone-item {
        padding: 20px 10px;
        border-bottom: 1px solid #DCDCDC;
        &:nth-last-of-type(1) {
          border-bottom: none;
        }
        .v-cardone-item-left {
          flex: 1;
          .iconfont {
            color: $main_color;
            font-size: 16px;
          }
          .v-cardone-item-left-text {
            font-size: 14px;
            padding-left: 10px;
          }
        }

        .v-cardone-item-right {
          padding: 0 10px;
          .v-cardone-item-right-text {
            color: #878787;
            font-size: 14px;
          }
          .icon-you {
            font-size: 22px;
            color: #7D929C;
          }
        }

      }
    }
  }

  .v-my-center-cardtwo {
    padding: 7px 12px 14px 12px;
    .v-my-center-cardtwo-list {
      background: $white;
      border-radius: 8px;
      .v-my-center-cardtwo-item {
        padding: 20px 10px;
        border-bottom: 1px solid #DCDCDC;
        &:nth-last-of-type(1) {
          border-bottom: none;
        }
        .v-cardtwo-item-left {
          flex: 1;
          img {
            width: 19px;
            height: 19px;
            object-fit: cover;
          }
          .v-cardtwo-item-left-text {
            font-size: 14px;
            padding-left: 10px;
          }
        }
        .v-cardtwo-item-right {
          padding: 0 10px;
          .v-cardtwo-item-left-text {
            color: #878787;
            font-size: 14px;
          }
          .icon-you {
            font-size: 22px;
            color: #7D929C;
          }
        }
      }
    }
  }

  .v-my-center-exit-login {
    margin: 0 0 20px 0;
  }
}
</style>